/** @jsx jsx */
import { jsx } from 'theme-ui'
import { useState } from 'react'
import { MdMenu } from 'react-icons/md'
import { IconContext } from 'react-icons'
import { Button, Box } from '@theme-ui/components'
import { Link } from 'gatsby'

const MenuButton = ({ onClick, onBlur }) => {
  return (
    <Button
      onClick={onClick}
      onBlur={() => setTimeout(onBlur, 50)}
      sx={{
        outline: 'none',
        px: 1,
        py: 0,
        border: 'none',
        color: 'text',
        backgroundColor: 'transparent',
      }}
    >
      <IconContext.Provider value={{ size: '24px', color: 'white' }}>
        <MdMenu />
      </IconContext.Provider>
    </Button>
  )
}

const Menu = ({ items }) => {
  return (
    <ul
      sx={{
        boxShadow: ({ boxShadow }) => boxShadow,
        position: 'absolute',
        right: '0px',
        borderRadius: '4px',
        mx: 3,
        mt: 2,
        py: 2,
        px: 0,
        zIndex: 1000,
        minWidth: '250px',
        backgroundColor: 'background',
        listStyle: 'none',
      }}
    >
      {items.map((item, index) => {
        return (
          <li
            sx={{
              ':not(:last-child)': {
                mb: 1,
              },
              px: 3,
              ':hover': {
                backgroundColor: 'whitesmoke',
              },
              a: {
                textDecoration: 'none',
                color: 'text',
              },
            }}
            key={index}
          >
            <Link to={item.path}>{item.text}</Link>
          </li>
        )
      })}
    </ul>
  )
}

const MobileNavMenu = ({ items }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Box>
      <MenuButton
        onClick={() => setIsOpen(!isOpen)}
        onBlur={() => setTimeout(() => setIsOpen(false), 200)}
      />
      {isOpen && <Menu items={items} />}
    </Box>
  )
}

export default MobileNavMenu
