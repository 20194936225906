/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Fragment } from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import Image from './image-static'
import MobileNavMenu from './mobile-nav-menu'

const items = [
  { text: 'Home', path: '/' },
  { text: 'Blog', path: '/blog' },
  { text: 'Classes', path: '/classes' },
  { text: 'About', path: '/about' },
]

const NavBar = () => (
  <Fragment>
    <div
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        backgroundColor: 'primary',
        height: '80px',
        paddingLeft: 2,
        paddingRight: 0,

        // it's kind of an implementation detail that the logo is inside of a div
        // and thus doesn't get hidden on mobile but oh well
        '& > a': {
          paddingLeft: 3,
          paddingRight: 2,
          fontSize: 2,
          ':hover': {
            fontWeight: 'bold',
          },
          color: 'white',
          display: ['none', 'inherit', 'inherit'],
          textDecoration: 'none',
        },
      }}
    >
      {/* This is a hack because the image asset itself has padding :( */}
      <div sx={{ ml: -3 }}>
        <Link to="/">
          {/* <img src={logo} sx={{ height: '60px' }} /> */}
          <Image />
        </Link>
      </div>

      {items.map((item, index) => {
        return (
          <Link key={index} to={item.path}>
            {item.text}
          </Link>
        )
      })}

      <div sx={{ flexGrow: '1' }}></div>
      <div
        sx={{
          color: 'white',
          display: ['inherit', 'none', 'none'],
        }}
      >
        <MobileNavMenu items={items} />
      </div>
    </div>
  </Fragment>
)

NavBar.propTypes = {
  siteTitle: PropTypes.string,
}

NavBar.defaultProps = {
  siteTitle: '',
}

export default NavBar
